<template>
  <div>
    <div class="select_box">
      <span>数据时间</span>
      <el-date-picker
        v-model="datevalue"
        type="daterange"
        align="right"
        unlink-panels
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="datePick"
      ></el-date-picker>
      <span></span>
    </div>
    <div class="select_box select_type">
      <span>数据类型</span>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane class="tabs_type" label="合作客户" name="first"></el-tab-pane>
        <el-tab-pane class="tabs_type" label="意向客户" name="second"></el-tab-pane>
        <!-- <el-tab-pane class="tabs_type" label="小程序" name="third"></el-tab-pane> -->
      </el-tabs>
    </div>
    <!-- 图表 -->
    <div class="merter" v-loading="eLoading">
      <div id="mains"></div>
    </div>
  </div>
</template>  
  
  <script>
export default {
  data () {
    return {
      eLoading: false,
      activeName: 'first',
      options: [],
      datevalue: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dataParams: {
        type: 1,
        start_time: '',
        end_time: '',
      },
      dataX: [],
      dataY: [],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.allLine()
    })
  },
  created () {
    this.getData()
  },
  methods: {
    datePick () {
      this.dataParams.start_time = this.datevalue[0]
      this.dataParams.end_time = this.datevalue[1]
      this.getData()
    },
    getData () {
      this.axios.get('/api/company/total_statistics', { params: { ...this.dataParams } }).then((res) => {
        console.log(res, '总计新增数据')
        this.dataX = res.data.date
        this.dataY = res.data.count
        this.allLine()
      }).catch((err) => {
        this.$message.error(err.message)
      })
    },
    handleClick (tab) {
      this.eLoading = true
      if (tab.name == 'first') {
        this.getData(this.dataParams.type = 1) //合作
        this.eLoading = false
      } else if (tab.name == 'second') {
        this.getData(this.dataParams.type = 0) //意向
        this.eLoading = false
      } else if (tab.name == 'third') {
        this.$message.warning('拼命开发中···')
        this.eLoading = false
      }
    },
    allLine () {
      var chartDom = document.getElementById('mains');
      var myChart = this.$echarts.init(chartDom);
      var option;

      //图表宽高自适应
      this.$nextTick(() => {
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
      option = {
        title: {
          text: '趋势图',
          left: '3%',
          textStyle: {
            fontWeight: 'none'
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: 20,
          textStyle: {
            color: '#fff'
          },
          formatter: function (params) {
            return (
              '<div>' +
              params[0].name +
              '<br>' +
              '新增' +
              ' ' +
              params[0].value +
              '</span>家' +
              '</div>'
            );
          }
        },
        legend: {
          data: ['Email']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
          type: 'category',
          boundaryGap: false,
          data: this.dataX,
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 20,
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            symbol: 'circle',
            symbolSize: 6,
            lineStyle: {
              color: '#FF7E0F'
            },
            itemStyle: {
              color: '#FF7E0F'
            },
            data: this.dataY
          }
        ]
      };

      option && myChart.setOption(option);
    },
  }
}
  </script> 
  
  <style scoped>
div/deep/.el-select {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-range-editor.el-input__inner {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-tabs {
  margin: 0 15px;
}
div/deep/.el-tabs--card > .el-tabs__header {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: none;
  margin-right: 15px;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: 1px solid #0f38ff;
  border-radius: 20px;
  color: #0f38ff;
}
div/deep/.el-tabs__item {
  color: #909399;
}
.select_box {
  margin-top: 30px;
}
.select_type {
  display: flex;
  align-items: center;
}
.merter {
  max-width: 100%;
  height: 500px;
}
#mains {
  margin-top: 60px;
  width: 100%;
  height: 100%;
}
</style>