<template>
  <div>
    <div class="select_box">
      <span>查看人员</span>
      <el-select v-model="dataParams.member_id" placeholder="请选择人员" @change="personal_statistics">
        <el-option
          clearable
          v-for="item in options"
          :key="item.id"
          :label="item.true_name"
          :value="item.id"
        ></el-option>
      </el-select>
      <span>备注：此选项，仅限管理人员或者主管使用</span>
    </div>
    <div class="select_box">
      <span>数据时间</span>
      <el-date-picker
        v-model="datevalue"
        type="daterange"
        align="right"
        value-format="yyyy-MM-dd"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        @change="datePick"
      ></el-date-picker>
      <span></span>
    </div>
    <div class="select_box select_type">
      <span>数据类型</span>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane class="tabs_type" label="合作客户" name="first"></el-tab-pane>
        <el-tab-pane class="tabs_type" label="意向客户" name="second"></el-tab-pane>
        <!-- <el-tab-pane class="tabs_type" label="小程序" name="third"></el-tab-pane> -->
      </el-tabs>
    </div>
    <!-- 图表 -->
    <div class="merter" v-loading="eLoading">
      <div id="main"></div>
    </div>
  </div>
</template>  

<script>
export default {
  data () {
    return {
      eLoading: false,
      value: '',
      activeName: 'first',
      options: [],
      datevalue: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dataParams: {
        type: 1,
        start_time: '',
        end_time: '',
        member_id: '',
      },
      dataX: [],
      dataY: []
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.myselfLine()
    })
  },
  created () {
    // this.getData()
    this.getMember()
  },
  methods: {
    personal_statistics () {
      this.getData()
    },
    getMember () {
      this.axios.get('/api/member/select_member', { params: { organization_id: 6 } }).then((res) => {
        console.log(res, 888)
        this.options = res.data
      })
    },
    datePick () {
      this.dataParams.start_time = this.datevalue[0]
      this.dataParams.end_time = this.datevalue[1]
      this.getData()
    },
    handleClick (tab) {
      this.eLoading = true
      if (tab.name == 'first') {
        this.getData(this.dataParams.type = 1) //合作
        this.eLoading = false
      } else if (tab.name == 'second') {
        this.getData(this.dataParams.type = 0) //意向
        this.eLoading = false
      } else if (tab.name == 'third') {
        this.$message.warning('拼命开发中···')
        this.eLoading = false
      }
    },
    getData () {
      this.axios.get('/api/company/personal_statistics', { params: { ...this.dataParams } }).then((res) => {
        console.log(res, '个人统计数据')
        this.dataX = res.data.date
        this.dataY = res.data.count
        this.myselfLine()
      })
    },
    myselfLine () {
      var chartDom = document.getElementById('main');
      var myChart = this.$echarts.init(chartDom);
      var option;

      //图表宽高自适应
      this.$nextTick(() => {
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
      option = {
        title: {
          text: '趋势图',
          left: '3%',
          textStyle: {
            fontWeight: 'none'
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: 20,
          textStyle: {
            color: '#fff'
          },
          formatter: function (params) {
            return (
              '<div>' +
              params[0].name +
              '<br>' +
              '新增' +
              ' ' +
              params[0].value +
              '</span>家' +
              '</div>'
            );
          }
        },
        legend: {
          data: ['Email']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
          type: 'category',
          boundaryGap: false,
          data: this.dataX
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 20,
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
        },
        series: [
          {
            type: 'line',
            stack: 'Total',
            symbol: 'circle',
            symbolSize: 6,
            lineStyle: {
              color: '#0F38FF'
            },
            itemStyle: {
              color: '#0F38FF'
            },
            data: this.dataY
          }
        ]
      };

      option && myChart.setOption(option);
    },
  }
}
</script> 

<style scoped>
div/deep/.el-select {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-range-editor.el-input__inner {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-tabs {
  margin: 0 15px;
}
div/deep/.el-tabs--card > .el-tabs__header {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: none;
  margin-right: 15px;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: 1px solid #0f38ff;
  border-radius: 20px;
  color: #0f38ff;
}
div/deep/.el-tabs__item {
  color: #909399;
}
.select_box {
  margin-top: 30px;
}
.select_type {
  display: flex;
  align-items: center;
}
.merter {
  width: 100%;
  height: 500px;
}
#main {
  margin-top: 60px;
  width: 100%;
  height: 100%;
}
</style>