<template>
  <div>
    <div class="select_box select_type">
      <span>数据类型</span>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane class="tabs_type" label="今日" name="first"></el-tab-pane>
        <el-tab-pane class="tabs_type" label="昨日" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 图表 -->
    <div class="merter" v-loading="eLoading">
      <div id="main_data"></div>
    </div>
  </div>
</template>  
    
    <script>
export default {
  data () {
    return {
      eLoading: false,
      activeName: 'first',
      options: [],
      value2: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dataParams: {
        day_type: 1,
      },
      dataX: [],
      dataY: [],
      dataY_n: [],
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.dataBar()
    })
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.axios.get('/api/company/today_statistics', { params: { ...this.dataParams } }).then((res) => {
        console.log(res.data, '数据统计数据')
        for (var i in res.data) {
          this.dataX.push(res.data[i].name)
          this.dataY.push(res.data[i].cooperation)
          this.dataY_n.push(res.data[i].not_cooperation)
        }
        this.dataBar()
      })
      // .catch((err) => {
      //   this.$message.error(err.message)
      // })
    },
    handleClick (tab) {
      this.eLoading = true
      this.dataX = []
      this.dataY = []
      this.dataY_n = []
      if (tab.name == 'first') {
        this.getData(this.dataParams.day_type = 1) //今日
        this.eLoading = false
      } else if (tab.name == 'second') {
        this.getData(this.dataParams.day_type = 2) //昨日
        this.eLoading = false
      }
    },
    dataBar () {
      var chartDom = document.getElementById('main_data');
      var myChart = this.$echarts.init(chartDom);
      var option;

      //图表宽高自适应
      this.$nextTick(() => {
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      })
      option = {
        title: {
          text: '趋势图',
          left: '3%',
          textStyle: {
            fontWeight: 'none'
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: 20,
          textStyle: {
            color: '#fff'
          },
          formatter: function (params) {
            return (
              '<div>' +
              params[0].name +
              '<br>' +
              '合作客户：' +
              ' ' +
              params[0].value +
              '</span>家' +
              '</div>' +
              '意向客户：' +
              ' ' +
              params[1].value +
              '</span>家' +
              '</div>'
            );
          }
        },
        legend: {
          data: ['合作', '意向',]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        xAxis: {
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
          type: 'category',
          data: this.dataX,
        },
        yAxis: {
          type: 'value',
          min: 0,
          max: 20,
          axisLine: {
            lineStyle: {
              color: '#A6ABC7'
            }
          },
        },
        series: [
          {
            name: '合作',
            type: 'bar',
            barWidth: 15,
            barGap: 0,
            lineStyle: {
              color: '#FF7E0F'
            },
            color: [{
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: '#FF3C3C' }, // 设置颜色渐变
                { offset: 1, color: '#FFFFFF' }
              ],
            }],
            data: this.dataY
          },
          {
            name: '意向',
            type: 'bar',
            barWidth: 15,
            barGap: 0,
            lineStyle: {
              color: '#FF7E0F'
            },

            color: [{
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: '#1441FF' }, // 设置颜色渐变
                { offset: 1, color: '#FFFFFF' }
              ],
            }],
            data: this.dataY_n
          }
        ]
      };

      option && myChart.setOption(option);
    },
  }
}
    </script> 
    
    <style scoped>
div/deep/.el-select {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-range-editor.el-input__inner {
  margin: 0 15px;
  width: 320px;
}
div/deep/.el-tabs {
  margin: 0 15px;
}
div/deep/.el-tabs--card > .el-tabs__header {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: none;
  margin-right: 15px;
}
div/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border: 1px solid #0f38ff;
  border-radius: 20px;
  color: #0f38ff;
}
div/deep/.el-tabs__item {
  color: #909399;
}
.select_box {
  margin-top: 30px;
}
.select_type {
  display: flex;
  align-items: center;
}
.merter {
  width: 100%;
  height: 500px;
}
#main_data {
  margin-top: 60px;
  width: 100%;
  height: 100%;
}
</style>