<template>
  <div>
    <!-- 客户仪表 -->
    <Header title="客户仪表" index="首页" titleOne="客户管理" beforeTitle="客户仪表" />
    <div class="content">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="个人新增" name="first" :lazy="true">
          <Myself ref="my" v-if="activeName == 'first'" />
        </el-tab-pane>
        <el-tab-pane label="总计新增" name="second" :lazy="true">
          <All ref="all" v-if="activeName == 'second'" />
        </el-tab-pane>
        <el-tab-pane label="数据统计" name="third" :lazy="true">
          <Data ref="data" v-if="activeName == 'third'" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Myself from '../../components/custom/mester/myselfAdd.vue'
import All from '../../components/custom/mester/allAdd.vue'
import Data from '../../components/custom/mester/dataAdd.vue'
export default {
  data () {
    return {
      activeName: 'first',
    }
  },
  components: {
    Myself,
    All,
    Data,
  },
  methods: {
    handleClick (tab) {
      console.log(tab.name)
      if (tab.name == 'first') {
        // this.$refs.my.myselfLine()
        this.$refs.my.getData()
      } else if (tab.name == 'second') {
        // this.$refs.all.allLine()
        this.$refs.all.getData()
      } else if (tab.name == 'third') {
        // this.$refs.data.dataBar()
        this.$refs.data.getData()
      }
    },
  }
}
</script>

<style>
</style>